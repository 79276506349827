(function(module) {
try {
  module = angular.module('statsPagePartials');
} catch (e) {
  module = angular.module('statsPagePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/partials/admin.htm',
    '<div class="header-image header-image-sm">\n' +
    '	<div class="header-background"></div>\n' +
    '	<div class="header-title">\n' +
    '		<h1>Adminlist</h1>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="settings-box"></div>\n' +
    '\n' +
    '<div class="container space-top space-top-sm">\n' +
    '    <div class="row d-flex flex-row flex-wrap">\n' +
    '        <div class="col-md-4" ng-repeat="(group, admin) in admins">\n' +
    '            <div class="boxed">\n' +
    '                <h2 class="text-capitalize">{{group}}</h2>\n' +
    '								<div class="table-scrollable">\n' +
    '                <table class="table table-sm table-striped">\n' +
    '                    <thead>\n' +
    '                        <tr>\n' +
    '                            <th>Name</th>\n' +
    '                            <th>Lastseen</th>\n' +
    '                        </tr>\n' +
    '                    </thead>\n' +
    '                    <tbody>\n' +
    '                        <tr ng-repeat="player in admin | orderBy:\'-session.sessionEndTimestamp\'">\n' +
    '                            <td><a ng-href="/client/{{player.adminGUIDShort}}">{{player.alias.aliasName}}</a></td>\n' +
    '                            <td title="{{player.session.sessionEnd}}">{{player.session.sessionEndHuman}}</td>\n' +
    '                        </tr>\n' +
    '                    </tbody>\n' +
    '                </table>\n' +
    '							</div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '			</div>\n' +
    '</div>\n' +
    '<div class="footer w-100 copyright">\n' +
    '      <div class="container">\n' +
    '          <div class="row">\n' +
    '              <div class="col-md-6">\n' +
    '                  &copy; 2017 3xP\' Clan, all rights reserved.\n' +
    '              </div>\n' +
    '              <div class="col-md-6 text-md-right">\n' +
    '                      Designed by Moritz Reuß\n' +
    '              </div>\n' +
    '          </div>\n' +
    '      </div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('statsPagePartials');
} catch (e) {
  module = angular.module('statsPagePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/partials/autocompleteDropdown.htm',
    '<div style="width: 500px">\n' +
    '  <p>\n' +
    '  <span ng-show="data.clientRank != \'player\'" title="This player has permissions on our server ({{data.clientRank}})" class="flag-icon flag-icon-clan"></span>\n' +
    '   {{data.clientAlias}} | <small>Guid: {{data.clientGUID}}</small>\n' +
    '   <br />\n' +
    '    <small>Connections: {{data.clientConnections}}</small>\n' +
    '    | <small>Onlinetime: {{data.clientOnlinetime / 60 / 60 | number : 2}} hours</small>\n' +
    '    <br />\n' +
    '    <small>\n' +
    '      Country: <span title="Player is from: {{data.country.countryLong}}">{{data.country.countryShort}}</span>\n' +
    '      Status: <span title="Player is online atm." ng-if="data.clientOnline" class="badge badge-success">Online</span>\n' +
    '               <span title="Player is offline atm." ng-if="!data.clientOnline">Offline</span>\n' +
    '    </small>\n' +
    '\n' +
    '</p>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('statsPagePartials');
} catch (e) {
  module = angular.module('statsPagePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/partials/ban.htm',
    '<div class="header-image header-image-sm">\n' +
    '	<div class="header-background" style="background-image: url(\'img/server/header_{{miniServerlist[serverID].serverNameShort || \'index\'}}.jpg\')"></div>\n' +
    '	<div class="header-title">\n' +
    '        <h1>Bans of {{miniServerlist[serverID].serverName.substr(0,27) || \'unknown\'}}</h1>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="settings-box">\n' +
    '	<div class="container">\n' +
    '		<div class="row">\n' +
    '			<div class="col-md-12">\n' +
    '				<form class="form-inline">\n' +
    '                    <label for="inlineFormInput" class="mr-2">Name:</label>\n' +
    '					<tags-input ng-model="tags" class="col-md-3" display-property="clientAlias" placeholder="Names or GUIDs"\n' +
    '					replace-spaces-with-dashes="false"\n' +
    '					add-on-comma="true"\n' +
    '					min-length="3"\n' +
    '					key-property="clientGUID"\n' +
    '					on-tag-added="onTagAdded($tag)"\n' +
    '					on-tag-removed="onTagRemoved($tag)"\n' +
    '					>\n' +
    '								<auto-complete source="doAutocomplete($query)"\n' +
    '															 min-length="0"\n' +
    '															 load-on-focus="true"\n' +
    '															 load-on-empty="false"\n' +
    '															 max-results-to-show="32"\n' +
    '															 template="/partials/autocompleteDropdown.htm"></auto-complete>\n' +
    '							</tags-input>\n' +
    '\n' +
    '\n' +
    '					<label for="inlineFormInputGroup" class="mr-2">Time Range:</label>\n' +
    '					<div class="input-group mb-2 mr-sm-2 mb-sm-0 col-md-3">\n' +
    '						<input type="text" class="form-control form-control-xs date-picker"\n' +
    '						date-range-picker ng-model="dateRange"\n' +
    '					options="dateRangeOptions"  id="inlineFormInputGroup" placeholder="2017-01-05 16:20">\n' +
    '					</div>\n' +
    '\n' +
    '					<label class="mr-sm-2" for="cmds" class="mr-2">Type:</label>\n' +
    '					<select class="form-control form-control-xs mb-2 mr-sm-2 mb-sm-0" size="1" id="cmds" name="cmds" ng-model="banType" ng-change="startSearch()">\n' +
    '						<option value="all" selected>All</option>\n' +
    '						<option value="ban">Ban</option>\n' +
    '						<option value="tempban">Tempban</option>\n' +
    '						<option value="kick">Kick</option>\n' +
    '						<option value="warn">Warning</option>\n' +
    '					</select>\n' +
    '\n' +
    '					<label class="mr-sm-2" for="cmds" class="mr-2">Show Autobans:</label>\n' +
    '					<select class="form-control form-control-xs mb-2 mr-sm-2 mb-sm-0" size="1" id="cmds" name="cmds" ng-model="showAutobans" ng-change="startSearch()">\n' +
    '						<option value="all" selected>All</option>\n' +
    '						<option value="only">Only</option>\n' +
    '						<option value="none">None</option>\n' +
    '					</select>\n' +
    '				</form>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="container space-top space-top-sm">\n' +
    '    <div class="row">\n' +
    '        <div class="col-md-12">\n' +
    '            <div class="boxed" infinite-scroll="loadPage()" infinite-scroll-distance="0">\n' +
    '                <h2>Bans</h2>\n' +
    '                <table class="table table-sm table-striped">\n' +
    '                    <thead>\n' +
    '                        <tr>\n' +
    '                            <th class="table-date">Date</th>\n' +
    '                            <th class="table-client">Victim</th>\n' +
    '                            <th class="table-client">Kicker</th>\n' +
    '                            <th>Type</th>\n' +
    '                            <th>Reason</th>\n' +
    '                            <th class="table-server">Server</th>\n' +
    '                            <th>Expire</th>\n' +
    '                        </tr>\n' +
    '                    </thead>\n' +
    '                    <tbody>\n' +
    '                        <tr ng-repeat="ban in bans" ng-cloak>\n' +
    '                            <td>{{ban.banDate}}</td>\n' +
    '                            <td>\n' +
    '                                <a ng-href="client/{{ban.client.clientGUIDShort}}">{{ban.alias.aliasName || ban.client.alias.aliasName}}</a>\n' +
    '                            </td>\n' +
    '                            <td>\n' +
    '                                <span ng-show="ban.client_kicker.clientGUIDShort == \'xxxxxxxx\'" class="tag tag-primary">3xP\' Adminbot</span>\n' +
    '                                <a ng-show ="ban.client_kicker.clientGUIDShort != \'xxxxxxxx\'" ng-href="client/{{ban.client_kicker.clientGUIDShort}}">\n' +
    '                                {{ban.client_kicker.alias.aliasName}}\n' +
    '                            </td>\n' +
    '                            <td>\n' +
    '                                {{ban.banType}}\n' +
    '                            </td>\n' +
    '                            <td ng-bind-html="ban.banReason">\n' +
    '                            </td>\n' +
    '                            <td>\n' +
    '                                <a ng-href="server/{{ban.server.serverID}}">{{ban.server.serverName.substr(0,27)}}</a>\n' +
    '                            </td>\n' +
    '                            <td class="text-md-center">\n' +
    '                                <span class="tag tag-danger" ng-show="ban.banDecay == -1">never</span>\n' +
    '                                <span class="tag tag-warning" ng-show="ban.banDecay > 0">{{ban.banDecayHuman}}</span>\n' +
    '                                <span ng-show="ban.banDecay == 0">-</span>\n' +
    '                            </td>\n' +
    '                        </tr>\n' +
    '                    </tbody>\n' +
    '                </table>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div class="footer w-100 copyright">\n' +
    '      <div class="container">\n' +
    '          <div class="row">\n' +
    '              <div class="col-md-6">\n' +
    '                  &copy; 2017 3xP\' Clan, all rights reserved.\n' +
    '              </div>\n' +
    '              <div class="col-md-6 text-md-right">\n' +
    '                      Designed by Moritz Reuß\n' +
    '              </div>\n' +
    '          </div>\n' +
    '      </div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('statsPagePartials');
} catch (e) {
  module = angular.module('statsPagePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/partials/chat.htm',
    '<div class="header-image header-image-sm">\n' +
    '	<div class="header-background" style="background-image: url(\'img/server/header_{{miniServerlist[serverID].serverNameShort}}.jpg\')"></div>\n' +
    '	<div class="header-title">\n' +
    '		<h1>Chat of {{miniServerlist[serverID].serverName}}</h1>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="settings-box">\n' +
    '	<div class="container">\n' +
    '		<div class="row">\n' +
    '			<div class="col-md-12">\n' +
    '				<form class="form-inline">\n' +
    '					<label for="inlineFormInput" class="mr-2">Name:</label>\n' +
    '					<tags-input ng-model="tags" class="col-md-3" display-property="clientAlias" placeholder="Names or GUIDs"\n' +
    '					replace-spaces-with-dashes="false"\n' +
    '					add-on-comma="true"\n' +
    '					min-length="3"\n' +
    '					key-property="clientGUID"\n' +
    '					on-tag-added="onTagAdded($tag)"\n' +
    '                    on-tag-removed="onTagRemoved($tag)"\n' +
    '					>\n' +
    '					      <auto-complete source="doAutocomplete($query)"\n' +
    '					                     min-length="0"\n' +
    '					                     load-on-focus="true"\n' +
    '					                     load-on-empty="false"\n' +
    '					                     max-results-to-show="32"\n' +
    '															 template="/partials/autocompleteDropdown.htm"></auto-complete>\n' +
    '					    </tags-input>\n' +
    '\n' +
    '					<label for="inlineFormInputGroup" class="mr-2">Time Range:</label>\n' +
    '					<div class="input-group mb-2 mr-sm-2 mb-sm-0 col-md-3">\n' +
    '					  <input type="text" class="form-control form-control-xs date-picker"\n' +
    '						date-range-picker ng-model="dateRange"\n' +
    '					options="dateRangeOptions"  id="inlineFormInputGroup" placeholder="2017-01-05 16:20">\n' +
    '					</div>\n' +
    '\n' +
    '					<label class="mr-sm-2" for="order" class="mr-2">Order:</label>\n' +
    '					<select class="mb-2 mr-sm-2 mb-sm-0 col-md-1 form-control-xs" id="order" name="order" ng-model="order" ng-change="startSearch()">\n' +
    '						<option value="desc">Desc.</option>\n' +
    '						<option value="asc">Asc.</option>\n' +
    '					</select>\n' +
    '\n' +
    '					<label class="mr-sm-2" for="cmds" class="mr-2">Show CMDs:</label>\n' +
    '					<select class="mb-2 mr-sm-2 mb-sm-0 form-control-xs" id="cmds" name="cmds" ng-model="showCMDs" ng-change="startSearch()">\n' +
    '						<option value="all">All</option>\n' +
    '						<option value="only">Only</option>\n' +
    '						<option value="none">None</option>\n' +
    '					</select>\n' +
    '				</form>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="container space-top space-top-sm">\n' +
    '    <div class="row">\n' +
    '        <div class="col-md-12">\n' +
    '            <div class="boxed" infinite-scroll="loadPage()" infinite-scroll-distance="0">\n' +
    '                <h2>Chat</h2>\n' +
    '                <table class="table table-striped table-xs">\n' +
    '                    <thead>\n' +
    '                        <tr>\n' +
    '                            <th class="table-date" >Date</th>\n' +
    '                            <th class="table-client">Playername</th>\n' +
    '                            <th>Message</th>\n' +
    '                        </tr>\n' +
    '                    </thead>\n' +
    '                    <tbody>\n' +
    '                        <tr ng-repeat="chatMessage in chatMessages" ng-cloak id="chatMessage-{{chatMessage.chatID}}" ng-class="{\'bg-clan\':chatMessage.chatID==selected.chatID}">\n' +
    '                            <td title="ChatID: {{chatMessage.chatID}}">\n' +
    '                                <a href="#" ng-click="showChatArround(chatMessage)" >\n' +
    '                                    {{chatMessage.chatTime}}\n' +
    '                                </a>\n' +
    '                            </td>\n' +
    '                            <td>\n' +
    '                                <a ng-href="client/{{chatMessage.client.clientGUIDShort}}">{{chatMessage.client.alias.aliasName}}</a>\n' +
    '                            </td>\n' +
    '                            <td\n' +
    '                                ng-bind-html="chatMessage.chatMessage"\n' +
    '                                style="max-width: 600px;  word-break: break-all; ">\n' +
    '                            </td>\n' +
    '                        </tr>\n' +
    '                    </tbody>\n' +
    '                </table>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div class="footer w-100 copyright">\n' +
    '      <div class="container">\n' +
    '          <div class="row">\n' +
    '              <div class="col-md-6">\n' +
    '                  &copy; 2017 3xP\' Clan, all rights reserved.\n' +
    '              </div>\n' +
    '              <div class="col-md-6 text-md-right">\n' +
    '                      Designed by Moritz Reuß\n' +
    '              </div>\n' +
    '          </div>\n' +
    '      </div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('statsPagePartials');
} catch (e) {
  module = angular.module('statsPagePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/partials/index.htm',
    '<div class="header-image">\n' +
    '	<div class="header-background"></div>\n' +
    '	<div class="header-title">\n' +
    '		<h1>Welcome to the 3xP\' Statistics Page</h1>\n' +
    '	</div>\n' +
    '    <div class="header-subtitle">\n' +
    '        <h2>Here you can find statistics about all <br />of our servers and players </br /> including bans, chatlogs and more</h2>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div class="settings-box"></div>\n' +
    '\n' +
    '<div class="container">\n' +
    '    <div class="row">\n' +
    '      <div class="col-md-12">\n' +
    '        <h2 class="headline space-top">Our Servers</h2>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="server-container-outer container space-bottom">\n' +
    '	<div class="shadow"></div>\n' +
    '	<slick class="slides" data="servers" ng-if="viewLoaded" settings="slickConfig">\n' +
    '		<a ng-href="server/{{server.serverID}}" ng-repeat="server in servers">\n' +
    '            <div class="server-box-background server-box">\n' +
    '    			<div class="server-box" title="Mapname: {{server.rcon.serv.mapname || \'default\'}}" style="background-image: url(\'https://static.mirko911.de/img/maps/loadscreen_{{server.rcon.serv.mapname || \'default\'}}.jpg\')">\n' +
    '                       <div class="text">{{server.serverName.substr(0,27)}}</div>\n' +
    '    			</div>\n' +
    '            </div>\n' +
    '		</a>\n' +
    '	</slick>\n' +
    '</div>\n' +
    '\n' +
    '<div class="container">\n' +
    '	<div class="row space-bottom">\n' +
    '		<div class="col-lg-6 col-md-12">\n' +
    '			<h2 class="headline">Newest Players</h2>\n' +
    '\n' +
    '  			<table class="table table-line table-sm boxed">\n' +
    '  						<tr ng-repeat="client in lastClients">\n' +
    '  							<td class="text-md-left clientname">\n' +
    '  								<a ng-href="client/{{client.shortGUID}}">\n' +
    '  									{{client.alias.aliasName}}\n' +
    '                  </a>\n' +
    '  							</td>\n' +
    '  							<td class="text-md-left"><a ng-href="server/{{client.server.serverID}}">{{client.server.serverName.substr(0,27)}}</a></td>\n' +
    '  							<td class="text-md-right">{{client.session.sessionEndHuman}}</td>\n' +
    '  						</tr>\n' +
    '  			</table>\n' +
    '		</div>\n' +
    '		<div class="col-lg-6 col-md-12">\n' +
    '			<h2 class="headline">Latest Bans</h2>\n' +
    '\n' +
    '			<table class="table table-line table-sm boxed">\n' +
    '	            <tr ng-repeat="ban in bans">\n' +
    '                    <td class="text-md-left clientname">\n' +
    '                        <a ng-href="client/{{ban.client.clientGUIDShort}}">\n' +
    '                            {{ban.alias.aliasName || ban.client.alias.aliasName || \'Unknown User\'}}\n' +
    '                        </a>\n' +
    '                    </td>\n' +
    '					<td class="text-md-left">\n' +
    '                        <a ng-href="server/{{ban.server.serverID}}">\n' +
    '                            {{ban.server.serverName.substr(0,27)}}\n' +
    '                        </a>\n' +
    '                    </td>\n' +
    '					<td class="text-md-right">{{ban.banDateHuman}}</td>\n' +
    '				</tr>\n' +
    '			</table>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<div class="row">\n' +
    '		<div class="col-md-12">\n' +
    '			<h2 class="headline">Our Statistics</h2>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '\n' +
    '	<div class="row">\n' +
    '		<div class="col-md-12">\n' +
    '			<div class="boxed boxed-lg">\n' +
    '                <h2>Players Activity (Top 3 Servers)</h2>\n' +
    '					<div class="graph" google-chart chart="playerChart" id="chart-players">\n' +
    '					</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '\n' +
    '    <div class="row space-bottom">\n' +
    '        <div class="col-md-8">\n' +
    '            <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                    <div class="boxed">\n' +
    '                        <h2>Avarage length of stay (Top 3 Servers)</h2>\n' +
    '                        <div class="graph" google-chart chart="stayChart" id="chart-stay">\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-4">\n' +
    '            <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                    <div class="boxed boxed-sm">\n' +
    '                        <h2>Players in Database</h2>\n' +
    '						<div class="table-scrollable">\n' +
    '	                        <table class="table table-sm table-striped table-borderless">\n' +
    '	                            <tbody>\n' +
    '	                                <tr ng-repeat="list in playersInDatabase" ngShow="list.serverID > 0">\n' +
    '	                                    <td>\n' +
    '                                            <a ng-href="/server/{{miniServerlist[list.serverID].serverID || 0}}">\n' +
    '                                                {{miniServerlist[list.serverID].serverName.substr(0,27) || \'3xP\\\' Unknown Server\'}}\n' +
    '                                            </a>\n' +
    '                                        </td>\n' +
    '	                                    <td>{{list.cnt}}</td>\n' +
    '	                                </tr>\n' +
    '	                            </tbody>\n' +
    '	                        </table>\n' +
    '						</div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                    <div class="boxed boxed-sm">\n' +
    '                      <div class="graph graph-sm">\n' +
    '                        <h2>Most played maps</h2>\n' +
    '                        <div id="piechart"  google-chart chart="mapChart"></div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '        <div class="col-md-12">\n' +
    '            <h2 class="headline space-top">Global Map</h2>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div class="w-100" google-chart chart="geoChart" id="geochart" style="height: 800px;background:url(\'img/background_sea.jpg\') no-repeat center center fixed;\n' +
    '    background-repeat: no-repeat;\n' +
    '    -webkit-background-size: cover;\n' +
    '    -moz-background-size: cover;\n' +
    '    -o-background-size: cover;\n' +
    '    background-size: cover;padding: 20px">\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '<div class="footer clearfix">\n' +
    '  <div class="container">\n' +
    '    <div class="row">\n' +
    '      <div class="col-md-6 space-top space-top-sm">\n' +
    '				<div class="boxed boxed-dark pb-1">\n' +
    '        	<h2>Top 10 Playtime</h2>\n' +
    '\n' +
    '	        <table class="table table-xs table-borderless">\n' +
    '	            <tr ng-repeat="client in topOnlineTime">\n' +
    '	              <td>{{$index + 1}}.</td>\n' +
    '	              <td class="table-client">\n' +
    '                      <a ng-href="/client/{{client.clientGUIDShort}}">\n' +
    '                          {{client.aliasName}}\n' +
    '                      </a>\n' +
    '                  </td>\n' +
    '	              <td class="">{{client.sumOnlineTime/60/60 | number:0}} hours</td>\n' +
    '	            </tr>\n' +
    '	        </table>\n' +
    '				</div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="col-md-6 space-top space-top-sm">\n' +
    '		<div class="boxed boxed-dark pb-1">\n' +
    '        	<h2>Top 10 Kills</h2>\n' +
    '\n' +
    '	        <table class="table table-xs table-borderless">\n' +
    '	            <tr ng-repeat="client in topKills">\n' +
    '	              <td>{{$index + 1}}.</td>\n' +
    '                  <td>\n' +
    '                      <a href="/client/{{client.clientGUIDShort}}">\n' +
    '                          {{client.aliasName}}\n' +
    '                      </a>\n' +
    '                  </td>\n' +
    '                  <td class="text-md-sm">{{client.sumKills| number:0}} kills</td>\n' +
    '	            </tr>\n' +
    '	        </table>\n' +
    '	      </div>\n' +
    '		</div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="w-100 copyright">\n' +
    '      <div class="container">\n' +
    '          <div class="row">\n' +
    '              <div class="col-md-6">\n' +
    '                      &copy; 2017 3xP\' Clan, all rights reserved.\n' +
    '              </div>\n' +
    '              <div class="col-md-6 text-md-right">\n' +
    '                      Designed by Moritz Reuß\n' +
    '              </div>\n' +
    '          </div>\n' +
    '      </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('statsPagePartials');
} catch (e) {
  module = angular.module('statsPagePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/partials/player.htm',
    '<div class="header-image header-image-sm">\n' +
    '    <div class="header-background" style="background-image: url(\'img/server/header_{{miniServerlist[serverID].serverNameShort || \'index\'}}.jpg\')"></div>\n' +
    '	<div class="header-title">\n' +
    '		<h1>Playerstats of {{client.alias.aliasName}}</h1>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="settings-box">\n' +
    '  <div class="container">\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-2"  style="margin: 0 auto">\n' +
    '      <div class="dropdown">\n' +
    '        <a class="btn btn-secondary btn-sm form-control-xs dropdown-toggle" href="https://example.com" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n' +
    '            {{miniServerlist[serverID].serverName.substr(0,27)|| \'Overall Statistics\'}}\n' +
    '        </a>\n' +
    '\n' +
    '\n' +
    '        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">\n' +
    '            <a class="dropdown-item" ng-if="serverID" ng-href="/client/{{client.clientGUIDShort}}/">Overall Statistics</a>\n' +
    '            <a class="dropdown-item" ng-repeat="server in servers" ng-href="/client/{{client.clientGUIDShort}}/{{server.serverID}}">{{miniServerlist[server.serverID].serverName.substr(0,27)}}</a>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '</div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="container space-top space-top-sm">\n' +
    '  <div ng-if="playerNotFound" class="alert alert-info" role="alert">\n' +
    '      <strong>Error!</strong> This profile doesn\'t exist\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-4">\n' +
    '      <div class="row boxed info-box">\n' +
    '        <div class="col-md-6">Nickname</div>\n' +
    '        <div class="col-md-6 text-md-right blue">{{client.alias.aliasName}}</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-4">\n' +
    '      <div class="row boxed info-box">\n' +
    '        <div class="col-md-7">GUID</div>\n' +
    '        <div class="col-md-5 text-md-right blue">{{client.clientGUIDShort}}</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-4">\n' +
    '      <div class="row boxed info-box">\n' +
    '        <div class="col-md-6">Country</div>\n' +
    '        <div class="col-md-6 text-md-right blue">{{client.country.countryLong}}</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-3">\n' +
    '      <div class="row boxed info-box">\n' +
    '        <div class="col-md-6">Rank</div>\n' +
    '        <div class="col-md-6 text-md-right blue">{{client.rank}}</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-3">\n' +
    '      <div class="row boxed info-box">\n' +
    '        <div class="col-5">Onlinetime</div>\n' +
    '        <div class="col-7 text-md-right blue">{{client.clientOnlinetime / 60 / 60 | number : 2}} hours</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-3">\n' +
    '      <div class="row boxed info-box">\n' +
    '        <div class="col-md-6" title="Firstseen: {{sessionFirst.sessionStarted}}">Lastseen</div>\n' +
    '        <div class="col-md-6 text-md-right blue">\n' +
    '            <span ng-if="client.clientOnline">Online</span>\n' +
    '            <span ng-if="!client.clientOnline" title="{{sessionLast.sessionEnd}}">{{sessionLast.sessionEndHuman}}</span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-3">\n' +
    '      <div class="row boxed info-box">\n' +
    '        <div class="col-md-6">Connections</div>\n' +
    '        <div class="col-md-6 text-md-right blue">{{client.clientConnections}}</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-12">\n' +
    '      <div class="boxed">\n' +
    '        <h2>Online Activity</h2>\n' +
    '        <div class="graph"  google-chart chart="stayChart" id="chart-players"></div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-4">\n' +
    '      <div class="boxed boxed-lg">\n' +
    '        <h2>Aliases</h2>\n' +
    '        <div class="table-scrollable">\n' +
    '          <table class="table table-striped table-sm">\n' +
    '            <thead>\n' +
    '              <tr>\n' +
    '                <th>Nickname</th>\n' +
    '                <th>Used</th>\n' +
    '              </tr>\n' +
    '            </thead>\n' +
    '            <tbody>\n' +
    '              <tr ng-repeat="alias in aliases">\n' +
    '                <td>{{alias.aliasName}}</td>\n' +
    '                <td>{{alias.aliasCount}}</td>\n' +
    '              </tr>\n' +
    '            </tbody>\n' +
    '          </table>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-4">\n' +
    '      <div class="boxed boxed-sm">\n' +
    '        <h2>Statistics</h2>\n' +
    '        <table class="table table-sm">\n' +
    '          <tbody>\n' +
    '            <tr>\n' +
    '              <td>Kills</td>\n' +
    '              <td></td>\n' +
    '              <td>{{client.kills}}</td>\n' +
    '            </tr>\n' +
    '            <tr>\n' +
    '              <td>Deaths</td>\n' +
    '              <td></td>\n' +
    '              <td>{{client.deaths}}</td>\n' +
    '            </tr>\n' +
    '            <tr>\n' +
    '              <td title="Kill / Death Ratio">K/D</td>\n' +
    '              <td></td>\n' +
    '              <td>{{client.kd}}</td>\n' +
    '            </tr>\n' +
    '            <tr>\n' +
    '              <td>Headshots</td>\n' +
    '              <td></td>\n' +
    '              <td>500</td>\n' +
    '            </tr>\n' +
    '            <tr>\n' +
    '              <td title="Kills per Minute">KPM</td>\n' +
    '              <td></td>\n' +
    '              <td>{{client.kills / ((client.clientOnlinetime + 1) / 60) | number : 2}}</td>\n' +
    '            </tr>\n' +
    '          </tbody>\n' +
    '        </table>\n' +
    '      </div>\n' +
    '      <div class="boxed boxed-sm">\n' +
    '          <div class="graph graph-sm" >\n' +
    '          <h2>Most played maps</h2>\n' +
    '            <div google-chart chart="mapChart"></div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-4">\n' +
    '      <div class="boxed boxed-sm">\n' +
    '        <h2>Ranks</h2>\n' +
    '        <table class="table table-sm">\n' +
    '          <tbody>\n' +
    '            <tr>\n' +
    '              <td>Kills</td>\n' +
    '              <td>\n' +
    '                  <img ng-show="ranks.kills[0].rank === 1" src="img/trophy_gold.svg" alt="Gold Trophy" title="1st player"/>\n' +
    '                  <img ng-show="ranks.kills[0].rank === 2" src="img/trophy_silver.svg" alt="Silver Trophy" title="2nd player"/>\n' +
    '                  <img ng-show="ranks.kills[0].rank === 3" src="img/trophy_bronze.svg" alt="Bronze Trophy" title="3rd player"/>\n' +
    '              </td>\n' +
    '              <td>{{ranks.kills[0].rank | ordinal}}</td>\n' +
    '            </tr>\n' +
    '            <tr>\n' +
    '              <td>Deaths</td>\n' +
    '              <td>\n' +
    '                  <img ng-show="ranks.deaths[0].rank === 1" src="img/trophy_gold.svg" alt="Gold Trophy" title="1st player"/>\n' +
    '                  <img ng-show="ranks.deaths[0].rank === 2" src="img/trophy_silver.svg" alt="Silver Trophy" title="2nd player"/>\n' +
    '                  <img ng-show="ranks.deaths[0].rank === 3" src="img/trophy_bronze.svg" alt="Bronze Trophy" title="3rd player"/>\n' +
    '              </td>\n' +
    '              <td>{{ranks.deaths[0].rank | ordinal}}</td>\n' +
    '            </tr>\n' +
    '            <tr>\n' +
    '              <td>K/D</td>\n' +
    '              <td>\n' +
    '                  <img ng-show="ranks.kd[0].rank === 1" src="img/trophy_gold.svg" alt="Gold Trophy" title="1st player"/>\n' +
    '                  <img ng-show="ranks.kd[0].rank === 2" src="img/trophy_silver.svg" alt="Silver Trophy" title="2nd player"/>\n' +
    '                  <img ng-show="ranks.kd[0].rank === 3" src="img/trophy_bronze.svg" alt="Bronze Trophy" title="3rd player"/>\n' +
    '              </td>\n' +
    '              <td>{{ranks.kd[0].rank | ordinal}}</td>\n' +
    '            </tr>\n' +
    '            <tr>\n' +
    '              <td>Headshots</td>\n' +
    '              <td>\n' +
    '                  <img ng-show="$index === 0" src="img/trophy_gold.svg" alt="Gold Trophy" title="1st player"/>\n' +
    '                  <img ng-show="$index === 1" src="img/trophy_silver.svg" alt="Silver Trophy" title="2nd player"/>\n' +
    '                  <img ng-show="$index === 2" src="img/trophy_bronze.svg" alt="Bronze Trophy" title="3rd player"/>\n' +
    '              </td>\n' +
    '              <td>2nd</td>\n' +
    '            </tr>\n' +
    '            <tr>\n' +
    '              <td>KPM</td>\n' +
    '              <td>\n' +
    '                  <img ng-show="ranks.kpm[0].rank === 1" src="img/trophy_gold.svg" alt="Gold Trophy" title="1st player"/>\n' +
    '                  <img ng-show="ranks.kpm[0].rank === 2" src="img/trophy_silver.svg" alt="Silver Trophy" title="2nd player"/>\n' +
    '                  <img ng-show="ranks.kpm[0].rank === 3" src="img/trophy_bronze.svg" alt="Bronze Trophy" title="3rd player"/>\n' +
    '              </td>\n' +
    '              <td>{{ranks.kpm[0].rank | ordinal}}</td>\n' +
    '            </tr>\n' +
    '          </tbody>\n' +
    '        </table>\n' +
    '      </div>\n' +
    '      <div class="boxed boxed-sm">\n' +
    '          <div class="graph graph-sm" >\n' +
    '          <h2>Most played servers</h2>\n' +
    '            <div google-chart chart="serverChart"></div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-8">\n' +
    '      <div class="boxed boxed-lg">\n' +
    '        <h2>Last Chat Activity</h2>\n' +
    '        <div class="table-scrollable">\n' +
    '          <table class="table table-xs">\n' +
    '            <thead>\n' +
    '              <tr>\n' +
    '                <th class="table-date">Time</th>\n' +
    '                <th class="table-server">Server</th>\n' +
    '                <th>Message</th>\n' +
    '              </tr>\n' +
    '            </thead>\n' +
    '            <tbody>\n' +
    '              <tr ng-repeat="chat in chats">\n' +
    '                <td>{{chat.chatTime}}</td>\n' +
    '                <td><a ng-href="/server/{{chat.server.serverID}}">{{chat.server.serverName.substr(0,27)}}</a></td>\n' +
    '                <td>{{chat.chatMessage}}</td>\n' +
    '              </tr>\n' +
    '            </tbody>\n' +
    '          </table>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-4">\n' +
    '      <div class="boxed boxed-sm">\n' +
    '        <h2>Multiaccounts based on IP</h2>\n' +
    '        <div class="table-scrollable">\n' +
    '          <table class="table table-sm">\n' +
    '            <thead>\n' +
    '              <tr>\n' +
    '                <th>Name</th>\n' +
    '                <th>Guid</th>\n' +
    '                <th>Lastseen</th>\n' +
    '              </tr>\n' +
    '            </thead>\n' +
    '            <tbody>\n' +
    '              <tr ng-repeat="session in multiAccounts">\n' +
    '                <td><a ng-href="/client/{{session.client.clientGUIDShort}}">{{session.client.alias.aliasName}}</a></td>\n' +
    '                <td><a ng-href="/client/{{session.client.clientGUIDShort}}">{{session.client.clientGUIDShort}}</a></td>\n' +
    '                <td title="{{session.sessionEnd}}">{{session.sessionEndHuman}}</td>\n' +
    '              </tr>\n' +
    '            </tbody>\n' +
    '          </table>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="boxed boxed-sm">\n' +
    '          <div class="graph graph-sm" >\n' +
    '          <h2>Favourite Weapons</h2>\n' +
    '            <div google-chart chart="weaponChart"></div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-12">\n' +
    '      <div class="boxed boxed-lg">\n' +
    '        <h2>Kicks and bans of this player</h2>\n' +
    '        <div class="table-scrollable">\n' +
    '          <table class="table table-sm">\n' +
    '            <thead>\n' +
    '              <tr>\n' +
    '                <th>Date</th>\n' +
    '                <th>Server</th>\n' +
    '                <th>Kicker</th>\n' +
    '                <th>Used Alias</th>\n' +
    '                <th>Reason</th>\n' +
    '                <th>Type</th>\n' +
    '                <th>Expire</th>\n' +
    '              </tr>\n' +
    '            </thead>\n' +
    '            <tbody>\n' +
    '              <tr ng-repeat="ban in bans" ng-cloak>\n' +
    '                <td>{{ban.banDate}}</td>\n' +
    '                <td><a ng-href="server/{{ban.server.serverID}}">{{ban.server.serverName.substr(0,27)}}</a></td>\n' +
    '                <td><span ng-show="ban.client_kicker.clientGUIDShort == \'xxxxxxxx\'" class="tag tag-primary">3xP\' Adminbot</span>\n' +
    '                    <a ng-show ="ban.client_kicker.clientGUIDShort != \'xxxxxxxx\'" ng-href="client/{{ban.client_kicker.clientGUIDShort}}">\n' +
    '                    {{ban.client_kicker.alias.aliasName}}</span>\n' +
    '                </td>\n' +
    '                <td>\n' +
    '                  <a ng-href="client/{{ban.client.clientGUIDShort}}">\n' +
    '                    {{ban.alias.aliasName ||ban.client.alias.aliasName}}\n' +
    '                  </a>\n' +
    '                </td>\n' +
    '                <td ng-bind-html="ban.banReason"></td>\n' +
    '                <td>{{ban.banType}}</td>\n' +
    '                <td>\n' +
    '                    <span class="tag tag-danger" ng-show="ban.banDecay == -1">never</span>\n' +
    '                    <span class="tag tag-warning" ng-show="ban.banDecay > 0">{{ban.banDecayHuman}}</span>\n' +
    '                    <span ng-show="ban.banDecay == 0">-</span>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '            </tbody>\n' +
    '          </table>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-12">\n' +
    '      <div class="boxed boxed-lg">\n' +
    '        <h2>Kicks and bans by this player</h2>\n' +
    '        <div class="table-scrollable">\n' +
    '          <table class="table table-sm">\n' +
    '            <thead>\n' +
    '              <tr>\n' +
    '                <th>Date</th>\n' +
    '                <th>Server</th>\n' +
    '                <th>Victim</th>\n' +
    '                <th>Reason</th>\n' +
    '                <th>Type</th>\n' +
    '                <th>Expire</th>\n' +
    '              </tr>\n' +
    '            </thead>\n' +
    '            <tbody>\n' +
    '              <tr ng-repeat="ban in bans_kicker" ng-cloak>\n' +
    '                <td>{{ban.banDate}}</td>\n' +
    '                <td><a ng-href="server/{{ban.server.serverID}}">{{ban.server.serverName.substr(0,27)}}</a></td>\n' +
    '                <td>\n' +
    '                  <a ng-href="client/{{ban.client.clientGUIDShort}}">{{ban.alias.aliasName || ban.client.alias.aliasName}}</a>\n' +
    '                </td>\n' +
    '                <td>{{ban.banType}}</td>\n' +
    '                <td ng-bind-html="ban.banReason"></td>\n' +
    '                <td>\n' +
    '                    <span class="tag tag-danger" ng-show="ban.banDecay == -1">never</span>\n' +
    '                    <span class="tag tag-warning" ng-show="ban.banDecay > 0">{{ban.banDecayHuman}}</span>\n' +
    '                    <span ng-show="ban.banDecay == 0">-</span>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '            </tbody>\n' +
    '          </table>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '<div class="footer w-100 copyright">\n' +
    '      <div class="container">\n' +
    '          <div class="row">\n' +
    '              <div class="col-md-6">\n' +
    '                  &copy; 2017 3xP\' Clan, all rights reserved.\n' +
    '              </div>\n' +
    '              <div class="col-md-6 text-md-right">\n' +
    '                      Designed by Moritz Reuß\n' +
    '              </div>\n' +
    '          </div>\n' +
    '      </div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('statsPagePartials');
} catch (e) {
  module = angular.module('statsPagePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/partials/player_mini.htm',
    '<style>.navbar-custom{display: none;}\n' +
    '        .signature .container{\n' +
    '            width: 792px;\n' +
    '        }\n' +
    '        .signature .settings-box{\n' +
    '            height: 20px;\n' +
    '        }\n' +
    '        .signature .settings-box .dropdown{\n' +
    '            line-height: 1;\n' +
    '        }\n' +
    '        .signature .settings-box .btn{\n' +
    '            line-height: 1;\n' +
    '            border: 0;\n' +
    '            font-size: 12px;\n' +
    '            font-weight: 600;\n' +
    '            width: 100%;\n' +
    '        }\n' +
    '        .signature .boxed.boxed-sm{\n' +
    '            width: 250px;\n' +
    '            height: 131px;\n' +
    '            margin: 7px;\n' +
    '            padding: 14px;\n' +
    '            font-size: 10px;\n' +
    '            float: left;\n' +
    '\n' +
    '        }\n' +
    '        .signature .space-top.space-top-sm{\n' +
    '            margin-top: 7px;\n' +
    '        }\n' +
    '        .signature .boxed h2{\n' +
    '            font-size: 12px;\n' +
    '            margin-bottom: 10px;\n' +
    '        }\n' +
    '        .signature .graph.graph-sm{\n' +
    '            height:103px;\n' +
    '        }\n' +
    '\n' +
    '</style>\n' +
    '<div class="signature" style="width:792px; height: 187px">\n' +
    '    <div class="settings-box">\n' +
    '      <div class="container">\n' +
    '      <div class="row">\n' +
    '        <div class="col-3"  style="margin: 0 auto">\n' +
    '          <div class="dropdown">\n' +
    '            <a class="btn btn-secondary btn-sm form-control-xs" href="#">\n' +
    '                {{miniServerlist[serverID].serverName.substr(0,27)|| \'Overall Statistics\'}}\n' +
    '            </a>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div style="\n' +
    '    width: 10px;\n' +
    '    position: absolute;\n' +
    '    right: 12px;\n' +
    '    top: -2px;">\n' +
    '        <a ng-if="serverID" ng-href="/client/{{client.clientGUIDShort}}{{\'/\' + serverID}}" target="_blank" title="Open the full stats page of this user in a new tab">\n' +
    '            <i class="icon-link-ext"></i>\n' +
    '        </a>\n' +
    '        <a ng-if="!serverID" ng-href="/client/{{client.clientGUIDShort}}" target="_blank" title="Open the full stats page of this user in a new tab">\n' +
    '            <i class="icon-link-ext"></i>\n' +
    '        </a>\n' +
    '    </div>\n' +
    '    </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="container space-top space-top-sm">\n' +
    '  <div class="row">\n' +
    '        <div class="boxed boxed-sm">\n' +
    '            <h2>General Information</h2>\n' +
    '            <table class="table table-xs">\n' +
    '                <tr>\n' +
    '                    <td>Nickname</td>\n' +
    '                    <td class="text-right blue">{{client.alias.aliasName}}</td>\n' +
    '                </tr>\n' +
    '                <tr>\n' +
    '                    <td>GUID</td>\n' +
    '                    <td class="text-right blue">{{client.clientGUIDShort}}</td>\n' +
    '                </tr>\n' +
    '                <tr>\n' +
    '                    <td>Onlinetime</td>\n' +
    '                    <td class="text-right blue">{{client.clientOnlinetime / 60 / 60 | number : 2}} hours</td>\n' +
    '                </tr>\n' +
    '                <tr>\n' +
    '                    <td>Country</td>\n' +
    '                    <td class="text-right blue">{{client.country.countryLong}}</td>\n' +
    '                </tr>\n' +
    '                <tr>\n' +
    '                    <td>Lastseen</td>\n' +
    '                    <td class="text-right blue">\n' +
    '                        <span ng-if="client.clientOnline">Online</span>\n' +
    '                        <span ng-if="!client.clientOnline" title="{{sessionLast.sessionEnd}}">{{sessionLast.sessionEndHuman}}</span>\n' +
    '                    </td>\n' +
    '                </tr>\n' +
    '            </table>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="boxed boxed-sm">\n' +
    '            <div class="graph graph-sm" >\n' +
    '                <h2>Most played servers</h2>\n' +
    '              <div google-chart chart="serverChart" class="graph graph-sm" ></div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="boxed boxed-sm">\n' +
    '            <h2 style="z-index: 5000">Online Activity</h2>\n' +
    '\n' +
    '            <div class="graph graph-sm" style="position: relative;" >\n' +
    '                <div google-chart chart="stayChart" id="chart-players" style="height:80px; width: 222px"></div>\n' +
    '              </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('statsPagePartials');
} catch (e) {
  module = angular.module('statsPagePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/partials/search.htm',
    '<div class="header-image header-image-sm">\n' +
    '	<div class="header-background" style="background-image: url(\'img/server/header_index.jpg\')"></div>\n' +
    '	<div class="header-title">\n' +
    '		<h1>Search</h1>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="settings-box">\n' +
    '    <div class="container">\n' +
    '        <div class="row">\n' +
    '            <div class="col-12">\n' +
    '                <form class="form-inline">\n' +
    '                    <label for="inlineFormInputGroup" class="mr-2">Name: </label>\n' +
    '                    <div class="form-inline mb-2 mr-sm-2 mb-sm-0 col-md-8">\n' +
    '                      <input type="text" class="form-control form-control-xs" placeholder="Name or GUID" ng-model="search" ng-change="doSearch()"/>\n' +
    '                    </div>\n' +
    '                </form>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<div class="container space-top space-top-sm">\n' +
    '  <div class="row">\n' +
    '      <div class="col-md-12">\n' +
    '          <div class="boxed boxed-lg">\n' +
    '              <h2>Search Results</h2>\n' +
    '              <table class="table table-striped table-xs">\n' +
    '                  <thead>\n' +
    '                      <tr>\n' +
    '                          <th class="table-client">\n' +
    '                              <a href="#" ng-click="sortBy(\'priority\')">\n' +
    '                                  Playername\n' +
    '                                  <i ng-show="orderByValue == \'priority\'" ng-class="{\'icon-angle-down\':orderReverse, \'icon-angle-up\':!orderReverse}"></i>\n' +
    '                              </a>\n' +
    '                          </th>\n' +
    '                          <th>\n' +
    '                              <a href="#" ng-click="sortBy(\'clientGUID\')">\n' +
    '                                  GUID\n' +
    '                                  <i ng-show="orderByValue == \'clientGUID\'" ng-class="{\'icon-angle-down\':orderReverse, \'icon-angle-up\':!orderReverse}"></i>\n' +
    '                              </a>\n' +
    '                          </th>\n' +
    '                          <th>\n' +
    '                              <a href="#" ng-click="sortBy(\'clientOnlinetime\')">\n' +
    '                                  Onlinetime\n' +
    '                                  <i ng-show="orderByValue == \'clientOnlinetime\'" ng-class="{\'icon-angle-down\':orderReverse, \'icon-angle-up\':!orderReverse}"></i>\n' +
    '                              </a>\n' +
    '                          </th>\n' +
    '                          <th>\n' +
    '                              <a href="#" ng-click="sortBy(\'sessionLast.sessionEndTimestamp\')">\n' +
    '                                  Lastseen\n' +
    '                                  <i ng-show="orderByValue == \'sessionLast.sessionEndTimestamp\'" ng-class="{\'icon-angle-down\':orderReverse, \'icon-angle-up\':!orderReverse}"></i>\n' +
    '                              </a>\n' +
    '                          </th>\n' +
    '                          <th>\n' +
    '                              <a href="#" ng-click="sortBy(\'sessionFirst.sessionStarted\')" class="table-date">\n' +
    '                                  Firstseen\n' +
    '                                  <i ng-show="orderByValue == \'sessionFirst.sessionStarted\'" ng-class="{\'icon-angle-down\':orderReverse, \'icon-angle-up\':!orderReverse}"></i>\n' +
    '                              </a>\n' +
    '                          </th>\n' +
    '                          <th>\n' +
    '                              <a href="#" ng-click="sortBy(\'clientRank\')">\n' +
    '                                  Rank\n' +
    '                                  <i ng-show="orderByValue == \'clientRank\'" ng-class="{\'icon-angle-down\':orderReverse, \'icon-angle-up\':!orderReverse}"></i>\n' +
    '                              </a>\n' +
    '                          </th>\n' +
    '                      </tr>\n' +
    '                  </thead>\n' +
    '                  <tbody>\n' +
    '\n' +
    '                      <tr ng-repeat="client in results | orderBy: orderByValue:orderReverse" ng-cloak>\n' +
    '                          <td>\n' +
    '                              <a ng-href="/client/{{client.clientGUID}}">{{client.clientAlias}}</a>\n' +
    '                          </td>\n' +
    '                          <td>\n' +
    '                              <a ng-href="/client/{{client.clientGUID}}">\n' +
    '                                  {{client.clientGUID}}\n' +
    '                              </a>\n' +
    '                          </td>\n' +
    '                          <td>{{client.clientOnlinetime / 60 / 60 | number: 2}} hours </td>\n' +
    '                          <td title="{{client.sessionLast.sessionEnd}}">{{client.sessionLast.sessionEndHuman}}</td>\n' +
    '                          <td>{{client.sessionFirst.sessionStarted}}</td>\n' +
    '                          <td class="text-capitalize">{{client.clientRank}}</td>\n' +
    '                      </tr>\n' +
    '                  </tbody>\n' +
    '              </table>\n' +
    '          </div>\n' +
    '      </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="footer w-100 copyright">\n' +
    '      <div class="container">\n' +
    '          <div class="row">\n' +
    '              <div class="col-md-6">\n' +
    '                  &copy; 2017 3xP\' Clan, all rights reserved.\n' +
    '              </div>\n' +
    '              <div class="col-md-6 text-md-right">\n' +
    '                      Designed by Moritz Reuß\n' +
    '              </div>\n' +
    '          </div>\n' +
    '      </div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('statsPagePartials');
} catch (e) {
  module = angular.module('statsPagePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/partials/server.htm',
    '<div class="header-image header-image-sm">\n' +
    '    <div class="header-background" style="background-image: url(\'img/server/header_{{miniServerlist[serverID].serverNameShort || \'index\'}}.jpg\')"></div>\n' +
    '	<div class="header-title">\n' +
    '		<h1>{{server.serverName.substr(0,27)}}</h1>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="settings-box"></div>\n' +
    '\n' +
    '<div class="container space-top space-top-sm">\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-3 col-12">\n' +
    '      <div class="row boxed info-box">\n' +
    '        <div class="col-5">Players</div>\n' +
    '        <div class="col-7 text-right blue">{{server.rcon.clients.length}}/{{server.rcon.serv.sv_maxclients}}</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-3 col-12">\n' +
    '      <div class="row boxed info-box">\n' +
    '        <div class="col-6">Online Status</div>\n' +
    '        <div class="col-6 text-right blue">\n' +
    '          <span ng-if="server.rcon">online</span>\n' +
    '          <span ng-if="!server.rcon">offline</span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-3 col-12">\n' +
    '      <div class="row boxed info-box">\n' +
    '        <div class="col-5">Uptime</div>\n' +
    '        <div class="col-7 text-right blue">{{server.rcon.serv.uptime}}</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-3 col-12">\n' +
    '      <div class="row boxed info-box">\n' +
    '        <div class="col-5">GSManager</div>\n' +
    '        <div class="col-7 text-right blue">online</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-4 col-12">\n' +
    '      <div class="row boxed info-box">\n' +
    '        <div class="col-4">IP</div>\n' +
    '        <div class="col-8 text-right blue">{{server.serverIP}}:{{server.serverPort}}</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-4 col-12">\n' +
    '      <div class="row boxed info-box">\n' +
    '        <div class="col-4">Map</div>\n' +
    '        <div class="col-8 text-right blue">{{server.rcon.serv.mapname}}</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-4 col-12">\n' +
    '      <div class="row boxed info-box">\n' +
    '        <div class="col-3">Mod</div>\n' +
    '        <div class="col-9 text-right blue">\n' +
    '          <span ng-if="server.rcon.serv.fs_game">{{server.rcon.serv.fs_game}}</span>\n' +
    '          <span ng-if="!server.rcon.serv.fs_game">/</span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-12">\n' +
    '      <div class="boxed boxed-lg">\n' +
    '        <h2>Players Activity</h2>\n' +
    '        <div class="graph"  google-chart chart="playerChart" id="chart-players"></div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '        <div class="col-md-4">\n' +
    '            <div class="boxed boxed-sm">\n' +
    '                <h2>Top 3 - Playtime</h2>\n' +
    '                <table class="table">\n' +
    '                    <tbody>\n' +
    '                        <tr ng-repeat="client in topOnlineTime">\n' +
    '                            <td class="table-trophy">{{client.clientOnlinetime}} Hours</td>\n' +
    '                            <td class="text-md-center">\n' +
    '                                <img ng-show="$index === 0" src="img/trophy_gold.svg" alt="Gold Trophy" title="1st player"/>\n' +
    '                                <img ng-show="$index === 1" src="img/trophy_silver.svg" alt="Silver Trophy" title="2nd player"/>\n' +
    '                                <img ng-show="$index === 2" src="img/trophy_bronze.svg" alt="Bronze Trophy" title="3rd player"/>\n' +
    '                            </td>\n' +
    '                            <td class="text-md-right">\n' +
    '                                <a ng-href="/client/{{client.clientGUIDShort}}">{{client.alias.aliasName}}</a>\n' +
    '                            </td>\n' +
    '                        </tr>\n' +
    '                    </tbody>\n' +
    '                </table>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-4">\n' +
    '            <div class="boxed boxed-sm">\n' +
    '                <h2>Top 3 - Kills</h2>\n' +
    '                <table class="table">\n' +
    '                    <tbody>\n' +
    '                        <tr ng-repeat="client in topKills">\n' +
    '                            <td class="table-trophy">{{client.kills}}</td>\n' +
    '                            <td class="text-md-center">\n' +
    '                                <img ng-show="$index === 0" src="img/trophy_gold.svg" alt="Gold Trophy" title="1st player"/>\n' +
    '                                <img ng-show="$index === 1" src="img/trophy_silver.svg" alt="Silver Trophy" title="2nd player"/>\n' +
    '                                <img ng-show="$index === 2" src="img/trophy_bronze.svg" alt="Bronze Trophy" title="3rd player"/>\n' +
    '                            </td>\n' +
    '                            <td class="text-md-right">\n' +
    '                                <a ng-href="/client/{{client.clientGUIDShort}}">{{client.alias.aliasName}}</a>\n' +
    '                            </td>\n' +
    '                        </tr>\n' +
    '                    </tbody>\n' +
    '                </table>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-4">\n' +
    '            <div class="boxed boxed-sm">\n' +
    '                <div class="graph graph-sm" >\n' +
    '					<h2>Top Maps</h2>\n' +
    '					<div google-chart chart="mapChart"></div>\n' +
    '				</div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-6">\n' +
    '      <div class="boxed boxed-md">\n' +
    '        <h2>Online Players</h2>\n' +
    '        <div class="table-scrollable">\n' +
    '          <table class="table table-sm">\n' +
    '            <thead>\n' +
    '              <tr>\n' +
    '                <th>\n' +
    '                    <a href="#" ng-click="sortBy(\'aliasName\', \'online\')">\n' +
    '                        Name\n' +
    '                    <i ng-show="onlinePlayerOrder == \'aliasName\'" ng-class="{\'icon-angle-down\':onlinePlayerReverse, \'icon-angle-up\':!onlinePlayerReverse}"></i>\n' +
    '                </th>\n' +
    '                <th>\n' +
    '                    <a href="#" ng-click="sortBy(\'clientScore\', \'online\')">\n' +
    '                        Score\n' +
    '                    <i ng-show="onlinePlayerOrder == \'clientScore\'" ng-class="{\'icon-angle-down\':!onlinePlayerReverse, \'icon-angle-up\':onlinePlayerReverse}"></i>\n' +
    '                </th>\n' +
    '                <th>\n' +
    '                    <a href="#" ng-click="sortBy(\'clientPing\', \'online\')">\n' +
    '                        Ping\n' +
    '                    <i ng-show="onlinePlayerOrder == \'clientPing\'" ng-class="{\'icon-angle-down\':!onlinePlayerReverse, \'icon-angle-up\':onlinePlayerReverse}"></i>\n' +
    '                </th>\n' +
    '                <th>\n' +
    '                    <a href="#" ng-click="sortBy(\'clientOnlinetime\', \'online\')">\n' +
    '                        Online Time\n' +
    '                    <i ng-show="onlinePlayerOrder == \'clientOnlinetime\'" ng-class="{\'icon-angle-down\':onlinePlayerReverse, \'icon-angle-up\':!onlinePlayerReverse}"></i>\n' +
    '                </th>\n' +
    '              </tr>\n' +
    '            </thead>\n' +
    '            <tbody>\n' +
    '                <tr ng-repeat="client in server.rcon.clients | orderBy:onlinePlayerOrder:onlinePlayerReverse">\n' +
    '                  <td class="table-client">\n' +
    '                      <a ng-href="/client/{{client.clientGUIDShort}}">{{client.aliasName}}</a>\n' +
    '                  </td>\n' +
    '                  <td>{{client.clientScore}}</td>\n' +
    '                  <td>{{client.clientPing}}</td>\n' +
    '                  <td>{{client.clientOnlinetime / 60 / 60 | number: 2}} hours</td>\n' +
    '                </tr>\n' +
    '            </tbody>\n' +
    '          </table>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-6">\n' +
    '      <div class="boxed boxed-md">\n' +
    '        <h2>New Players</h2>\n' +
    '        <div class="table-scrollable">\n' +
    '          <table class="table table-sm">\n' +
    '            <thead>\n' +
    '              <tr>\n' +
    '                  <th>\n' +
    '                      <a href="#" ng-click="sortBy(\'alias.aliasName\', \'new\')">\n' +
    '                          Name\n' +
    '                          <i ng-show="newPlayerOrder == \'alias.aliasName\'" ng-class="{\'icon-angle-down\':newPlayerReverse, \'icon-angle-up\':!newPlayerReverse}"></i>\n' +
    '                      </a>\n' +
    '                    </th>\n' +
    '                    <th>\n' +
    '                      <a href="#" ng-click="sortBy(\'clientFirstseen\', \'new\')">\n' +
    '                          Firstseen\n' +
    '                          <i ng-show="newPlayerOrder == \'clientFirstseen\'" ng-class="{\'icon-angle-down\':newPlayerReverse, \'icon-angle-up\':!newPlayerReverse}"></i>\n' +
    '                      </a>\n' +
    '                  </th>\n' +
    '                  <th>\n' +
    '                      <a href="#" ng-click="sortBy(\'clientOnlinetime\', \'new\')">\n' +
    '                          Online Time\n' +
    '                          <i ng-show="newPlayerOrder == \'clientOnlinetime\'" ng-class="{\'icon-angle-down\':newPlayerReverse, \'icon-angle-up\':!newPlayerReverse}"></i>\n' +
    '                      </a>\n' +
    '                    </th>\n' +
    '              </tr>\n' +
    '            </thead>\n' +
    '            <tbody>\n' +
    '                <tr ng-repeat="client in newPlayers | orderBy:newPlayerOrder:newPlayerReverse">\n' +
    '                  <td class="clientname">\n' +
    '                      <a ng-href="/client/{{client.clientGUIDShort}}">\n' +
    '                          {{client.alias.aliasName}}\n' +
    '                      </a>\n' +
    '                  </td>\n' +
    '                  <td>\n' +
    '                      {{client.clientFirstseen}}\n' +
    '                  </td>\n' +
    '                  <td>\n' +
    '                      {{client.clientOnlinetime/60/60 | number : 2 }} hours\n' +
    '                  </td>\n' +
    '                </tr>\n' +
    '            </tbody>\n' +
    '          </table>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '<div class="row">\n' +
    '    <div class="col-md-4">\n' +
    '        <div class="boxed boxed-sm">\n' +
    '            <h2 title="Average stay time in minutes">Average length of stay</h2>\n' +
    '            <div class="graph graph-sm" google-chart chart="stayChart"></div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-4">\n' +
    '        <div class="boxed boxed-sm">\n' +
    '            <h2>Longest Rounds</h2>\n' +
    '            <div class="table-scrollable">\n' +
    '                <table class="table table-striped table-sm">\n' +
    '                    <tbody>\n' +
    '                        <tr ng-repeat="round in topRoundTime">\n' +
    '                            <td>{{round.map.mapNameShort}}</td>\n' +
    '                            <td title="Roundstart: {{round.roundStart}}, Roundend: {{round.roundEnd}}">{{round.roundTime}} hours</td>\n' +
    '                        </tr>\n' +
    '                    </tbody>\n' +
    '                </table>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-4">\n' +
    '        <div class="boxed boxed-sm">\n' +
    '            <h2 title="Average round time in minutes">Average round time</h2>\n' +
    '            <div class="graph graph-sm" google-chart chart="roundChart"></div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-8">\n' +
    '      <div class="boxed boxed-lg">\n' +
    '        <h2>Chat</h2>\n' +
    '			<div class="table-scrollable">\n' +
    '                <table class="table table-striped table-xs ">\n' +
    '                    <thead>\n' +
    '                        <tr>\n' +
    '                            <th class="table-date">Time</th>\n' +
    '                            <th class="table-client">Name</th>\n' +
    '                            <th>Chat message</th>\n' +
    '                        </tr>\n' +
    '                    </thead>\n' +
    '                    <tbody>\n' +
    '                        <tr ng-repeat="chatMessage in chatMessages" ng-cloak>\n' +
    '                            <td>{{chatMessage.chatTime}}</td>\n' +
    '                            <td class="">\n' +
    '                                <a ng-href="client/{{chatMessage.client.clientGUIDShort}}">{{chatMessage.client.alias.aliasName}}</a>\n' +
    '                            </td>\n' +
    '                            <td ng-bind-html="chatMessage.chatMessage"  style="word-break: break-all; "></td>\n' +
    '                        </tr>\n' +
    '                    </tbody>\n' +
    '                </table>\n' +
    '			</div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    <div class="col-md-4">\n' +
    '      <div class="row">\n' +
    '        <div class="col-md-12">\n' +
    '          <div class="boxed boxed-sm">\n' +
    '            <div class="graph graph-sm">\n' +
    '              <h2>Most played maps</h2>\n' +
    '              <div id="piechart"  google-chart chart="mapChart"></div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-12">\n' +
    '          <div class="boxed boxed-sm">\n' +
    '            <h2>Map History</h2>\n' +
    '            <div class="table-scrollable">\n' +
    '                <table class="table table-sm table-striped">\n' +
    '                    <tbody>\n' +
    '                        <tr ng-repeat="round in mapHistory">\n' +
    '                            <td title="RoundID: {{round.roundID}}">{{round.map.mapNameShort}}</td>\n' +
    '                            <td title="StartDate: {{round.roundStart}}, EndDate: {{round.roundEnd}}">{{round.roundEndHuman}}</td>\n' +
    '                        </tr>\n' +
    '                    </tbody>\n' +
    '                </table>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-12">\n' +
    '      <div class="boxed boxed-lg">\n' +
    '        <h2>Kicks and bans</h2>\n' +
    '        <div class="table-scrollable">\n' +
    '          <table class="table table-sm table-striped">\n' +
    '            <thead>\n' +
    '              <tr>\n' +
    '                <th class="table-date">Date</th>\n' +
    '				<th class="table-client">Victim</th>\n' +
    '				<th class="table-client">Kicker</th>\n' +
    '                <th>Reason</th>\n' +
    '                <th>Event</th>\n' +
    '                <th>Expire</th>\n' +
    '              </tr>\n' +
    '            </thead>\n' +
    '            <tbody>\n' +
    '              <tr ng-repeat="ban in bans" ng-cloak>\n' +
    '                <td title="BanID: {{ban.banID}}">{{ban.banDate}}</td>\n' +
    '                <td>\n' +
    '                  <a ng-href="client/{{ban.client.clientGUIDShort}}">\n' +
    '                    {{ban.alias.aliasName || ban.client.alias.aliasName}}\n' +
    '                  </a>\n' +
    '                </td>\n' +
    '                <td><span ng-show="ban.client_kicker.clientGUIDShort == \'xxxxxxxx\'" class="tag tag-primary">3xP\' Adminbot</span>\n' +
    '                    <a ng-show ="ban.client_kicker.clientGUIDShort != \'xxxxxxxx\'" ng-href="client/{{ban.client_kicker.clientGUIDShort}}">\n' +
    '                    {{ban.client_kicker.alias.aliasName}}</span>\n' +
    '                </td>\n' +
    '                <td>{{ban.banType}}</td>\n' +
    '                <td ng-bind-html="ban.banReason"></td>\n' +
    '                <td class="text-md-center">\n' +
    '                    <span class="tag tag-danger" ng-show="ban.banDecay == -1">never</span>\n' +
    '                    <span class="tag tag-warning" ng-show="ban.banDecay > 0">{{ban.banDecayHuman}}</span>\n' +
    '                    <span ng-show="ban.banDecay == 0">-</span>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '            </tbody>\n' +
    '          </table>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '      <div class="col-md-12">\n' +
    '          <h2 class="headline space-top">Global Map</h2>\n' +
    '      </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="w-100" google-chart chart="geoChart" id="geochart" style="height: 800px;background:url(\'img/background_sea.jpg\') no-repeat center center fixed;\n' +
    'background-repeat: no-repeat;\n' +
    '-webkit-background-size: cover;\n' +
    '-moz-background-size: cover;\n' +
    '-o-background-size: cover;\n' +
    'background-size: cover;padding: 20px">\n' +
    '\n' +
    '</div>\n' +
    '<div class="footer w-100 copyright">\n' +
    '      <div class="container">\n' +
    '          <div class="row">\n' +
    '              <div class="col-md-6">\n' +
    '                  &copy; 2017 3xP\' Clan, all rights reserved.\n' +
    '              </div>\n' +
    '              <div class="col-md-6 text-md-right">\n' +
    '                      Designed by Moritz Reuß\n' +
    '              </div>\n' +
    '          </div>\n' +
    '      </div>\n' +
    '</div>\n' +
    '');
}]);
})();
