/*global angular */

angular = require('angular');

angular.module('statsPage')
  .controller('adminCtrl', function($scope, $http, naviService, Page){
      naviService.getServerlist()
          .then(function(miniServerlist){
              Page.setTitle('Adminlist - 3xP\' Statistics Page');
          })

      $scope.admins = [];

      $http.get('https://api.3xp-clan.com/v1/admins?byGroups=1')
        .then(function(result){
          $scope.admins = result.data.admins;
      });
  });
