/*global angular */

angular = require('angular');
require('angular-slick-carousel');
require('angular-ui-router');
require('angular-sanitize');
require('angular-google-chart');
require('angular-daterangepicker');
require('ng-infinite-scroll');
require('ng-tags-input');
require('../dist/templateCachePartials');

angular.module('statsPage', ['ui.router', 'slickCarousel', 'statsPagePartials', 'ngSanitize', 'googlechart', 'daterangepicker', 'ngTagsInput', 'infinite-scroll'])
  .config(function($stateProvider, $urlRouterProvider, $locationProvider){
      $locationProvider.html5Mode(true);
      $urlRouterProvider.otherwise('/');
      $urlRouterProvider.when('', '/');

      $stateProvider
        .state('index', {
            'url' : '/',
            'templateUrl': '/partials/index.htm',
            'controller': 'indexCtrl'
        })
        .state('bans', {
            'url' : '/bans',
            'templateUrl': '/partials/ban.htm',
            'controller': 'banCtrl'
        })
        .state('bansserver', {
            'url' : '/bans/server/{serverID}',
            'templateUrl': '/partials/ban.htm',
            'controller': 'banCtrl'
        })
        .state('chats', {
            'url' : '/chat/server/{serverID}',
            'templateUrl': '/partials/chat.htm',
            'controller': 'chatCtrl'
        })
        .state('search', {
          'url' : '/search',
          'templateUrl' : '/partials/search.htm',
          'controller' : 'searchCtrl'
        })
        .state('clients', {
            'url' : '/client/{clientGUID}',
            'templateUrl': '/partials/player.htm',
            'controller': 'playerCtrl'
        })
        .state('clientsMini', {
            'url' : '/client/{clientGUID}/signature',
            'templateUrl': '/partials/player_mini.htm',
            'controller': 'playerCtrl'
        })
        .state('clientsServer', {
            'url' : '/client/{clientGUID}/{serverID}',
            'templateUrl': '/partials/player.htm',
            'controller': 'playerCtrl'
        })
        .state('clientMiniServer', {
            'url' : '/client/{clientGUID}/{serverID}/signature',
            'templateUrl': '/partials/player_mini.htm',
            'controller': 'playerCtrl'
        })
        .state('admins', {
            'url' : '/admins',
            'templateUrl': '/partials/admin.htm',
            'controller': 'adminCtrl'
        })
        .state('server', {
            'url' : '/server/{serverID}',
            'templateUrl': '/partials/server.htm',
            'controller': 'serverCtrl'
        })
        ;

  });
require('pageFactory');
require('naviService');
require('ordinalFilter');

require('mainCtrl');
require('indexCtrl');
require('banCtrl');
require('serverCtrl');
require('playerCtrl');
require('adminCtrl');
require('chatCtrl');
require('searchCtrl');

$('ul.navbar-nav li.dropdown').hover(function(){
    if($(window).width() > 767){
        $(this).find('.dropdown-menu').show();
    }
}, function(){
    if($(window).width() > 767){
        $(this).find('.dropdown-menu').hide();
    }});

$('.dropdown-toggle').click(function() {
if ($(this).next('.dropdown-menu').is(':visible')) {
    window.location = $(this).attr('href');
}
});

$(window).bind('scroll', function () {
    if ($(window).scrollTop() > 50) {
        $('.navbar-custom').addClass('sticky-top');
    } else {
        $('.navbar-custom').removeClass('sticky-top');
    }
});
