/*global angular */

angular = require('angular');

angular.module('statsPage')
    .factory('Page', function(){
      var title = 'default';
      return {
        title: function() { return title; },
        setTitle: function(newTitle) { title = newTitle; }
      };
    });
