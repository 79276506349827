/*global angular */

angular = require('angular');
angular.module('statsPage')
  .controller('indexCtrl', function($scope, $http, naviService, Page){

    naviService.getServerlist();

    Page.setTitle('Main Overwiew - 3xP\' Statistics Page');

    $scope.playersInDatabase = [];
    $scope.mostPlayedMaps = [];
    $scope.servers = [];
    $scope.lastClients = [];
    $scope.bans = [];

    $scope.slickConfig =  {
      enabled: true,
      autoplay: true,
      draggable: true,
      autoplaySpeed: 2200,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      fade: false,
      arrows: false,
      centerMode: true,
      variableWidth: true,
      response: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }
      ]
    };

    $http.get('https://api.3xp-clan.com/v1/server')
      .then(function(response){
        $scope.servers = response.data.servers;
        $scope.lastClients = response.data.clients;
        $scope.topKills = response.data.topKills;
        $scope.topOnlineTime = response.data.topOnline;
        $scope.viewLoaded = true;
      });

    $http.get('https://api.3xp-clan.com/v1/bans/?limit=10')
        .then(function(response){
            $scope.bans = response.data.bans;
        });

      $scope.playerChart = {
          "type": "LineChart",
          "options": {
            "chartArea": {"width": '100%', "height": '90%', "right": 0, "bottom": 0},
            "legend": { "position": 'top', 'alignment': 'end' },
            "hAxis": {
              "title": "",
              "textPosition" : "in"
            },
            "vAxis" : {
                "textPosition" : "in",
                "format" : '# connections',
                "title": ''
            }
          },
      };


      $scope.stayChart = {
        "type": "LineChart",
        "options": {
          "chartArea": {"width": '100%', "height": '96%', "right": 0, "bottom": 0},
          "legend": { "position": 'top', 'alignment': 'end' },
          "hAxis": {
            "title": "",
            "textPosition" : "in"
          },
          "vAxis" : {
              "textPosition" : "in",
              "format" : '# minutes',
            "title": ''
          },
        },
      };



      $http.get('https://api.3xp-clan.com/v1/graph/indexPage/')
        .then(function(response){
            $scope.playerChart.data = response.data.activity;
            $scope.stayChart.data = response.data.stay;
            for(i = 0; i < response.data.mostPlayedMaps.length; i++){
                $scope.mapChart.data.push([response.data.mostPlayedMaps[i].map.mapNameShort, response.data.mostPlayedMaps[i].cnt]);
            }
            for(i = 0; i < response.data.countries.length; i++){
                $scope.geoChart.data.push([response.data.countries[i].clientCountryLong, response.data.countries[i].cnt]);
            }
        });

        $http.get('https://api.3xp-clan.com/v1/client/count')
            .then(function(response){
                $scope.playersInDatabase = response.data.count;
        });




      $scope.geoChart = {
        "type": "GeoChart",
        "options": {
            "backgroundColor": "none",
            "legend": "none",
            "colorAxis": {colors: ['#99bfd8', '#3a9de0', '#0074C8']},
            "backgroundColor": 'transparent',
            "datalessRegionColor": '#f5f5f5',
            "defaultColor": '#f5f5f5',
        },
        "data" : [
            ['Country', 'Players of this country'],
        ]
      };



      $scope.mapChart = {
        "type" : 'PieChart',
        "options" : {
          "tooltip" : {"ignoreBounds" : true, "isHtml": true},
          "legend": {
                   "position": 'left',
                   "alignment": 'center',
                   "textStyle" : {"fontSize": 14}
                   },
          "chartArea": {"width": '100%', "height": '94%', "right": 0, "bottom": 5},
          "pieHole" : 0.4,
          "slices" : [
              {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0}
          ],
          "backgroundColor" : 'transparent',
        }
      };
      $scope.mapChart.data = [
        ['Task', 'Hours per Day'],
      ];

      $scope.banSearch = "";
      $scope.latestBanSearch = function(){
          if($scope.banSearch.length < 3) return;
          $http.get('https://api.3xp-clan.com/v1/client/simple?search='+$scope.banSearch).
          then(function(result){
              var guids = [];
              for(i = 0; i < result.data.length; i++){
                  guids.push(result.data[i].clientGUID);
              }
              var guidString = guids.join(',');
              $http.get('https://api.3xp-clan.com/v1/bans/victim/' + guidString + '?limit=10')
                .then(function(result){
                    $scope.bans = result.data.bans;
                });
          });
      };

      $scope.playerSearch = "";
      $scope.newPlayerSearch = function(){
          if($scope.playerSearch.length < 3) return;
          $http.get('https://api.3xp-clan.com/v1/client/simple?search='+$scope.playerSearch).
          then(function(result){
              var guids = [];
              for(i = 0; i < result.data.length; i++){
                  guids.push(result.data[i].clientGUID);
              }
              var guidString = guids.join(',');
              $http.get('https://api.3xp-clan.com/v1/client/' + guidString + '?limit=10')
                .then(function(result){
                    $scope.bans = result.data.bans;
                });
          });
      };
  });
