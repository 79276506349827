/*global angular */

angular = require('angular');

angular.module('statsPage')
  .controller('searchCtrl', function($scope, $http, naviService, Page, $location){

  naviService.getServerlist()
      .then(function(miniServerlist){
          Page.setTitle('Search - 3xP\' Statistics Page');
      })

    $scope.restoreSearchParams = function(){
        var search = $location.search();

        if("search" in search){
            $scope.search = search.search;
            $scope.doSearch();
        }
    }

    $scope.search = "";
    $scope.results = {};

    $scope.orderByValue = 'priority';
    $scope.orderReverse = false;

    $scope.sortBy = function(propertyName) {
      $scope.orderReverse = ($scope.orderByValue === propertyName) ? !$scope.orderReverse : false;
      $scope.orderByValue = propertyName;
    };


    $scope.doSearch = function(){
        if($scope.search.length < 3) return;

        $http.get('https://api.3xp-clan.com/v1/client/simple?search='+$scope.search)
        .then(function(response) {
            $scope.results = response.data;
            Page.setTitle($scope.search + ' - Search - 3xP\' Statistics Page');
            $location.search('search', $scope.search);
        });
    }

    $scope.restoreSearchParams();
  });
