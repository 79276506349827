/*global angular */

angular = require('angular');

angular.module('statsPage')
  .controller('chatCtrl', function($scope, $http, $stateParams, naviService, $location, Page ){
    $scope.serverID = $stateParams.serverID;

    naviService.getServerlist()
    .then(function(miniServerlist){
        Page.setTitle('Chatlog - ' + miniServerlist[$scope.serverID].serverName.substr(0,27) +' - 3xP\' Statistics Page');
    })

    $scope.dateRange = {
        startDate: null,
        endDate: null
    };

    $scope.dateRangeOptions = {
          timePicker: true,
          timePicker24Hour: true,
          opens: 'center',
            locale : {
                format : 'DD/MM/YYYY'
            },
            eventHandlers : {
                'apply.daterangepicker' : function(test1, test2) {
                    $scope.startSearch();
                }
            }
    };

    $scope.chatMessages = [];

    $scope.tags = [];
    $scope.order = 'desc';
    $scope.showCMDs = 'all';

    $scope.init = true;
    $scope.page = 1;
    $scope.pageLoadBusy = true;

    $scope.selectedChat = null;

    $scope.buildUrl = function(){
        var url = "https://api.3xp-clan.com/v1/chat/"+ $scope.serverID;

        var tagList = '';
        for(i = 0; i < $scope.tags.length; i++){
          tagList += ($scope.tags[i].clientGUID) + ',';
        }
        if(tagList.length != 0){
          tagList = tagList.substring(0, tagList.length - 1);
          $location.search('guids', tagList);
          var url = "https://api.3xp-clan.com/v1/chat/client/" + tagList + '/' + $scope.serverID;
        }

        url += '?order=' + $scope.order;
        url += '&showCMDs=' + $scope.showCMDs;
        url += '&page=' + $scope.page;

        if(!$scope.init){
            $location.search('order', $scope.order);
            $location.search('showCMDs', $scope.showCMDs)
        }


        if($scope.dateRange.startDate && $scope.dateRange.endDate){
          var start = $scope.dateRange.startDate.format("Y-M-DD H:mm:s");
          var end = $scope.dateRange.endDate.format("Y-M-DD H:mm:s");
          $location.search('start', start);
          $location.search('end', end);
          url += '&start=' + start + '&end=' + end;
        }
    //    $location.hash(null);

        return url;
    }

    $scope.startSearch = function(){
     $scope.page = 1;
     var url = $scope.buildUrl();
      $http.get(url)
        .then(function(response){
          $scope.chatMessages = response.data.chat;
          $scope.pageLoadBusy = false;
        });
    }

    $scope.onTagAdded = function($tag){
      $scope.startSearch();
    }
    $scope.onTagRemoved = function($tag){
      $scope.startSearch();
    }


    $scope.doAutocomplete = function($query) {
      return $http.get('https://api.3xp-clan.com/v1/client/simple?search='+$query)
      .then(function(response) {
        var clients = response.data;
        return clients;
      });
    }

    $scope.showChatArround = function(item){
        var url = "https://api.3xp-clan.com/v1/chat/arround/" + item.chatID;
        $http.get(url)
          .then(function(response){
            $scope.chatMessages = response.data.chat;
            $scope.selected = item;
            $location.hash('chatMessage-' + item.chatID);
          });
    }

    $scope.loadPage = function(){
        if($scope.pageLoadBusy) return;
        $scope.pageLoadBusy = true;
        $scope.page++;
        var url = $scope.buildUrl();
        $http.get(url)
          .then(function(response){
              for(i = 0; i < response.data.chat.length; i++)
                $scope.chatMessages.push(response.data.chat[i]);
                $scope.pageLoadBusy = false;
          });
    }

    $scope.restoreSearchParams = function(){
        var search = $location.search();
        if("showCMDs" in search){
            $scope.showCMDs = search.showCMDs;
        }
        if("order" in search){
            $scope.order = search.order;
        }
        if('guids' in search){
            var guids = search.guids.split(",");
            for(i = 0; i < guids.length; i++){
                $scope.tags.push({'clientGUID': guids[i], 'clientAlias': guids[i]});
            }
        }
        if("start" in search){
            $scope.dateRange.startDate = search.start;
        }
        if("end" in search){
            $scope.dateRange.endDate = search.end;
        }
        $scope.startSearch();
        $scope.init = false;
    }
    $scope.restoreSearchParams();
  });
