/*global angular */

angular = require('angular');

angular.module('statsPage')
  .controller('playerCtrl', function($scope, $http, $stateParams, naviService, Page, $q, $state){
    $scope.guid = $stateParams.clientGUID;
    $scope.getServerID = function(){
        if(angular.isUndefined($stateParams.serverID)){
            $scope.serverID = '';
        }else{
            $scope.serverID = $stateParams.serverID;
        }
    }

    $q.all



    $scope.getServerID();
    $scope.client = {};
    $scope.aliases = {};
    $scope.chats = {};
    $scope.session = {};
    $scope.bans_kicker = {};
    $scope.multiAccounts = {};
    $scope.servers = {}
    $scope.ranks = {}
    $scope.playerNotFound = false;

    $scope.stayChart = {
      "type": "LineChart",
      "options": {
        "chartArea": {"width": '100%', "height": '96%', "right": 0, "bottom": 0},
        "legend": { "position": 'top', 'alignment': 'end' },
        "hAxis": {
          "title": "",
          "textPosition" : "in"
        },
        "vAxis" : {
            "textPosition" : "in",
            "format" : '# minutes',
          "title": ''
        },
        "series": {
          1: {curveType: 'function'}
        }
      },
    };

    $scope.stayChart.data = [
    ];

    $scope.mapChart = {
      "type" : 'PieChart',
      "options" : {
        "tooltip" : {"ignoreBounds" : true, "isHtml": true},
        "legend": {
                 "position": 'left',
                 "alignment": 'center',
                 "textStyle" : {"fontSize": 14}
                 },
        "chartArea": {"width": '100%', "height": '94%', "right": 0, "bottom": 5},
        "pieHole" : 0.4,
        "slices" : [
            {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0}
        ],
        "backgroundColor" : 'transparent',
      }
    };
    $scope.mapChart.data = [
      ['Task', 'Hours per Day'],
    ];

    $scope.serverChart = {
      "type" : 'PieChart',
      "options" : {
        "tooltip" : {"ignoreBounds" : true, "isHtml": true},
        "legend": {
                 "position": 'left',
                 "alignment": 'center',
                 "textStyle" : {"fontSize": 14}
                 },
        "chartArea": {"width": '100%', "height": '94%', "right": 0, "bottom": 5},
        "pieHole" : 0.4,
        "slices" : [
            {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0}
        ],

        "backgroundColor" : 'transparent',
      }
    };

    if($state.current.name == "clientsMini" || $state.current.name == "clientMiniServer"){
        $scope.serverChart.options.legend.textStyle.fontSize = 8;
        $scope.stayChart.options.chartArea.height = 80;
        $scope.stayChart.options.vAxis.format = '';
        $scope.stayChart.options.vAxis.textPosition = 'none';
        $scope.stayChart.options.hAxis.textPosition = 'none';

        $(".navbar-custom").hide();

    }

    $scope.serverChart.data = [
      ['Server', 'Onlinetime'],
    ];

    $scope.weaponChart = {
      "type" : 'PieChart',
      "options" : {
        "tooltip" : {"ignoreBounds" : true, "isHtml": true},
        "legend": {
                 "position": 'left',
                 "alignment": 'center',
                 "textStyle" : {"fontSize": 14},
                 "maxLines": 5
                 },
        "chartArea": {"width": '100%', "height": '94%', "right": 0, "bottom": 5},
        "pieHole" : 0.4,
        "sliceVisibilityThreshold" : 0.10,
        "slices" : [
            {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0}
        ],
        "backgroundColor" : 'transparent',
      }
    };
    $scope.weaponChart.data = [
      ['Weapon', 'Kills'],
    ];


    $scope.getClient = function(){
        var d = $q.defer();
        $http.get('https://api.3xp-clan.com/v1/client/' + $scope.guid + '/' + $scope.serverID)
          .then(function(result){
            $scope.client = result.data.client;
            $scope.aliases = result.data.aliases;
            $scope.sessionFirst = result.data.sessionFirst;
            $scope.sessionLast = result.data.sessionLast;
            $scope.ranks = result.data.ranks;
            d.resolve($scope.client);
        }, function(result){
            $scope.playerNotFound = true;
        });
        return d.promise;
    }

    $scope.updateTitle = function(miniServerlist, client){
        var title = client.alias.aliasName;
        if($scope.serverID == ''){
            title += ' - Overall Statistics ';
        }else{
            title += ' - ' +  miniServerlist[$scope.serverID].serverName.substr(0,27);
        }
        title += ' - 3xP\' Statistics Page'
        Page.setTitle(title);
    }

    $q.all([
        naviService.getServerlist(),
        $scope.getClient()
    ]).then(function(data){
        $scope.updateTitle(data[0], data[1]);
    });


    $http.get('https://api.3xp-clan.com/v1/client/server/' + $scope.guid)
        .then(function(result){
            $scope.servers = result.data.servers;
        });


    $http.get('https://api.3xp-clan.com/v1/bans/victim/' + $scope.guid + '/' + $scope.serverID)
        .then(function(result){
            $scope.bans = result.data.bans;
        });

    $http.get('https://api.3xp-clan.com/v1/bans/kicker/' + $scope.guid + '/' + $scope.serverID)
        .then(function(result){
            $scope.bans_kicker = result.data.bans_kicker;
        });

    $http.get('https://api.3xp-clan.com/v1/chat/client/' + $scope.guid + '/' + $scope.serverID)
        .then(function(result){
            $scope.chats = result.data.chat;
        });


      $http.get("https://api.3xp-clan.com/v1/graph/stayClient/" + $scope.guid + '/' + $scope.serverID)
          .then(function(response){
              $scope.stayChart.data = response.data.stay;
      });

      $http.get("https://api.3xp-clan.com/v1/client/multiAccounts/" + $scope.guid + '/' + $scope.serverID)
          .then(function(response){
              $scope.multiAccounts = response.data.multiAccounts;
      });


/*
      $http.get('https://api.3xp-clan.com/v1/graph/mostPlayedMapsClient/' + $scope.guid)
          .then(function(response){
              for(i = 0; i < response.data.maps.length; i++){
                  $scope.mapChart.data.push([response.data.maps[i].map.mapNameShort, response.data.maps[i].cnt]);
              }
      });

      */

      $http.get('https://api.3xp-clan.com/v1/graph/mostPlayedServers/' + $scope.guid + '/' + $scope.serverID)
          .then(function(response){
              for(i = 0; i < response.data.sessions.length; i++){
                  $scope.serverChart.data.push([response.data.sessions[i].server.serverName.substr(0,27), response.data.sessions[i].cnt]);
              }
      });

      $http.get('https://api.3xp-clan.com/v1/graph/mostPlayedWeaponsClient/' + $scope.guid + '/' + $scope.serverID)
          .then(function(response){
              for(i = 0; i < response.data.weapons.length; i++){
                  $scope.weaponChart.data.push([response.data.weapons[i].weaponNameShort, response.data.weapons[i].cnt]);
              }
      });
  });
