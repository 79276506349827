/*global angular */

angular = require('angular');

angular.module('statsPage')
  .controller('serverCtrl', function($scope, $http, $stateParams, naviService, Page){
    naviService.getServerlist();

    naviService.getServerlist()
    .then(function(miniServerlist){
        Page.setTitle(miniServerlist[$scope.serverID].serverName.substr(0,27) +' - Server - 3xP\' Statistics Page');
    })

    $scope.serverID = $stateParams.serverID;
    $scope.bans = [];
    $scope.chatMessages = [];
    $scope.server = {};
    $scope.topOnlineTime = [];
    $scope.topRoundTime = [];
    $scope.topKills = [];
    $scope.newPlayers = [];
    $scope.mapHistory = [];

    $scope.getServerData = function(){
      if($scope.serverID){
        $http.get("https://api.3xp-clan.com/v1/chat/" + $scope.serverID)
          .then(function(response){
            $scope.chatMessages = response.data.chat;
          });

        $http.get("https://api.3xp-clan.com/v1/bans/" + $scope.serverID)
          .then(function(response){
            $scope.bans = response.data.bans;
          });
        $http.get("https://api.3xp-clan.com/v1/graph/activity/" + $scope.serverID)
            .then(function(response){
                $scope.playerChart.data = response.data.activity;
        });
        $http.get('https://api.3xp-clan.com/v1/graph/mostPlayedMaps/' + $scope.serverID)
            .then(function(response){
                for(i = 0; i < response.data.mostPlayedMaps.length; i++){
                    $scope.mapChart.data.push([response.data.mostPlayedMaps[i].map.mapNameShort, response.data.mostPlayedMaps[i].cnt]);
                }
        });

        $http.get('https://api.3xp-clan.com/v1/graph/globalMap/' + $scope.serverID)
            .then(function(response){
                for(i = 0; i < response.data.countries.length; i++){
                    $scope.geoChart.data.push([response.data.countries[i].clientCountryLong, response.data.countries[i].cnt]);
                }
        });

        $http.get('https://api.3xp-clan.com/v1/graph/stay/' + $scope.serverID)
            .then(function(response){
                $scope.stayChart.data = response.data.stay;
        });

        $http.get('https://api.3xp-clan.com/v1/graph/round/' + $scope.serverID)
            .then(function(response){
                $scope.roundChart.data = response.data.round;
        });


        $http.get("https://api.3xp-clan.com/v1/server/" + $scope.serverID)
          .then(function(response){
            $scope.server = response.data;
          });

        $http.get('https://api.3xp-clan.com/v1/server/' + $scope.serverID + '/playtime')
            .then(function(response){
                $scope.topOnlineTime = response.data.time;
        });

        $http.get('https://api.3xp-clan.com/v1/server/' + $scope.serverID + '/kills')
            .then(function(response){
                $scope.topKills = response.data.kills;
        });

        $http.get('https://api.3xp-clan.com/v1/server/' + $scope.serverID + '/newPlayers')
            .then(function(response){
                $scope.newPlayers = response.data.clients;
        });


        $http.get('https://api.3xp-clan.com/v1/server/' + $scope.serverID + '/roundtime')
            .then(function(response){
                $scope.topRoundTime = response.data.rounds;
        });

        $http.get('https://api.3xp-clan.com/v1/server/' + $scope.serverID + '/maphistory')
            .then(function(response){
                $scope.mapHistory = response.data.rounds;
        });
      }
    }

    $scope.getServerData();

    $scope.newPlayerOrder = 'clientFirstseen';
    $scope.newPlayerReverse = true;
    $scope.onlinePlayerOrder = 'clientScore';
    $scope.onlinePlayerReverse = true;

    $scope.sortBy = function(propertyName, type) {
        if(type === 'online'){
            $scope.onlinePlayerReverse = ($scope.onlinePlayerOrder === propertyName) ? !$scope.onlinePlayerReverse : false;
            $scope.onlinePlayerOrder = propertyName;
        }else if(type === 'new'){
            $scope.newPlayerReverse = ($scope.newPlayerOrder === propertyName) ? !$scope.newPlayerReverse : false;
            $scope.newPlayerOrder = propertyName;
        }
    };


    $scope.playerChart = {
        "type": "LineChart",
        "options": {
          "chartArea": {"width": '100%', "height": '96%', "right": 0, "bottom": 0},
          "legend": { "position": 'top', 'alignment': 'end' },
          "hAxis": {
            "title": "",
            "textPosition" : "in"
          },
          "vAxis" : {
              "textPosition" : "in",
              "format" : '# connections',
            "title": ''
        }//,
        //  "series": {
        //    1: {curveType: 'function'}
        //  }
        }
    };

    $scope.stayChart = {
        "type": "LineChart",
        "options": {
          "chartArea": {"width": '100%', "height": '96%', "right": 0, "bottom": 0},
          "legend": { "position": 'top', 'alignment': 'end' },
          "hAxis": {
            "title": "",
            "textPosition" : "in"
          },
          "vAxis" : {
              "textPosition" : "in",
              "format" : '# min',

            "title": ''
        }//,
        //  "series": {
        //    1: {curveType: 'function'}
        //  }
        }
    };

    $scope.roundChart = {
        "type": "LineChart",
        "options": {
          "chartArea": {"width": '100%', "height": '96%', "right": 0, "bottom": 0},
          "legend": { "position": 'top', 'alignment': 'end' },
          "hAxis": {
            "title": "",
            "textPosition" : "in"
          },
          "vAxis" : {
              "textPosition" : "in",
              "format" : '# min',

            "title": ''
        }//,
        //  "series": {
        //    1: {curveType: 'function'}
        //  }
        }
    };

    $scope.playerChart.data = {
        "cols" : [
            {"label": "X", "type": "date"},
            {"label": "Players", "type":"number"}
        ],
        "rows" : [
        ]
    };

    $scope.roundChart.data = {
        "cols" : [
            {"label": "X", "type": "date"},
            {"label": "Players", "type":"number"}
        ],
        "rows" : [
        ]
    };

    $scope.stayChart.data = {
        "cols" : [
            {"label": "X", "type": "date"},
            {"label": "Players", "type":"number"}
        ],
        "rows" : [
        ]
    };

    $scope.mapChart = {
      "type" : 'PieChart',
      "options" : {
        "tooltip" : {"ignoreBounds" : true, "isHtml": true},
        "legend": {
                 "position": 'left',
                 "alignment": 'center',
                 "textStyle" : {"fontSize": 14}
                 },
        "chartArea": {"width": '100%', "height": '94%', "right": 0, "bottom": 5},
        "pieHole" : 0.4,
        "slices" : [
            {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0},  {'offset' : 0}
        ],
        "backgroundColor" : 'transparent',
      }
    };
    $scope.mapChart.data = [
      ['Task', 'Hours per Day'],
    ];

    $scope.topMaps = $scope.mapChart;


      $scope.geoChart = {
        "type": "GeoChart",
        "options": {
            "backgroundColor": "none",
            "legend": "none",
            "colorAxis": {colors: ['#99bfd8', '#3a9de0', '#0074C8']},
            "backgroundColor": 'transparent',
            "datalessRegionColor": '#f5f5f5',
            "defaultColor": '#f5f5f5',
        },
        "data" : [
            ['Country', 'Popularity'],
        ]
      };
  });
