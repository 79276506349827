/*global angular */

angular = require('angular');

angular.module('statsPage')
  .service('naviService', function($http, $rootScope, $q) {
      this.getServerlist = function(){
        if(!$rootScope.miniServerlist){
            var deferred = $q.defer();

            $http.get('https://api.3xp-clan.com/v1/server/minimal')
            .then(function(response){
              $rootScope.miniServerlist = response.data.server;
              deferred.resolve($rootScope.miniServerlist);
            });
            $rootScope.miniServerlist = deferred.promise;
        }
        return $q.when($rootScope.miniServerlist);
      }
  });
