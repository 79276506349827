/*global angular */

angular = require('angular');

angular.module('statsPage')
  .controller('banCtrl', function($scope, $http, $stateParams, naviService, Page, $location ){
    $scope.serverID = $stateParams.serverID;
    naviService.getServerlist()
    .then(function(miniServerlist){
        Page.setTitle('Banlist - ' + miniServerlist[$scope.serverID].serverName.substr(0,27) +' - 3xP\' Statistics Page');
    })

    $scope.bans = [];
    $scope.tags = [];
    $scope.banType = 'all';
    $scope.startDate = '';
    $scope.endDate = '';
    $scope.showAutobans = 'none';
    $scope.init = true;

    $scope.page = 1;
    $scope.pageLoadBusy = true;

    $scope.dateRange = {
            startDate: null,
            endDate: null
    };

    $scope.dateRangeOptions = {
          timePicker: true,
          timePicker24Hour: true,
          opens: 'center',
            locale : {
                format : 'DD/MM/YYYY'
            },
            eventHandlers : {
                'apply.daterangepicker' : function(test1, test2) {
                    $scope.startSearch();
                }
            }
    };

    $scope.onTagAdded = function($tag){
      $scope.startSearch();
    }
    $scope.onTagRemoved = function($tag){
      $scope.startSearch();
    }

    $scope.buildUrl = function(){
        var url = "https://api.3xp-clan.com/v1/bans";
        if($scope.serverID){
          url += '/' + $scope.serverID;
        }

        var tagList = '';
        for(i = 0; i < $scope.tags.length; i++){
          tagList += ($scope.tags[i].clientGUID) + ',';
        }
        if(tagList.length != 0){
          tagList = tagList.substring(0, tagList.length - 1);
          $location.search('guids', tagList);
          url = "https://api.3xp-clan.com/v1/bans/victim/" + tagList;
          if($scope.serverID){
            url += '/' + $scope.serverID;
          }
        }

        url += '?type=' + $scope.banType;
        url += '&showAutobans=' + $scope.showAutobans;
        url += '&page=' + $scope.page;

        if(!$scope.init){
            $location.search('type', $scope.banType);
            $location.search('showAutobans', $scope.showAutobans);
        }

        if($scope.dateRange.startDate && $scope.dateRange.endDate){
          var start = $scope.dateRange.startDate.format("Y-M-DD H:mm:s");
          var end = $scope.dateRange.endDate.format("Y-M-DD H:mm:s");
          $location.search('start', start);
          $location.search('end', end);
          url += '&start=' + start + '&end=' + end;
        }
        return url;
    }

    $scope.startSearch = function(){
      $scope.page = 1;
      var url = $scope.buildUrl();
      $scope.pageLoadBusy = true;
      $http.get(url)
        .then(function(response){
          $scope.bans = response.data.bans;
          $scope.pageLoadBusy = false;

        });
        $scope.init = false;

    }


    $scope.restoreSearchParams = function(){
        var search = $location.search();
        if("showAutobans" in search){
            $scope.showAutobans = search.showAutobans;
        }
        if("type" in search){
            $scope.banType = search.type;
        }
        if('guids' in search){
            var guids = search.guids.split(",");
            for(i = 0; i < guids.length; i++){
                $scope.tags.push({'clientGUID': guids[i], 'clientAlias': guids[i]});
            }
        }
        if("start" in search){
            $scope.dateRange.startDate = search.start;
        }
        if("end" in search){
            $scope.dateRange.endDate = search.end;
        }
        $scope.startSearch();
    }

    $scope.doAutocomplete = function($query) {
      return $http.get('https://api.3xp-clan.com/v1/client/simple?search='+$query)
      .then(function(response) {
        var clients = response.data;
        return clients;
      });
    }

    $scope.loadPage = function(){
        if($scope.pageLoadBusy) return;
        $scope.pageLoadBusy = true;
        $scope.page++;
        var url = $scope.buildUrl();

        $http.get(url)
          .then(function(response){
              for(i = 0; i < response.data.bans.length; i++)
                $scope.bans.push(response.data.bans[i]);
                $scope.pageLoadBusy = false;
          });

    }

    $scope.restoreSearchParams();

  });
